import { mapActions } from 'vuex';
import { ElMessageBox, ElMessage } from 'element-plus';
export default {
  props: {
    event: {
      type: Object,
      required: true,
      default: () => {}
    }
  },

  setup() {
    const archiveModal = (archiveEvent, eventId) => {
      ElMessageBox.confirm(`Är ni säkra på att ni vill arkivera bokningen?
      Varken admins eller leverantören kan se bokningen efter att den har blivit arkiverad!`, 'Arkivera bokningen', {
        confirmButtonText: 'Arkivera',
        cancelButtonText: 'Avbryt',
        type: 'warning'
      }).then(() => {
        archiveEvent(eventId).then(() => {
          ElMessage({
            type: 'success',
            message: 'Bokningen arkiverad!'
          });
        }).catch(() => {
          ElMessage({
            type: 'error',
            message: 'Bokningen kunde inte arkiveras!'
          });
        });
      });
    }; // Customer actions


    const createCustomerInvoiceModal = (createCustomerInvoice, eventId) => {
      ElMessageBox.confirm(`Är du säker på att du vill skapa en kundfaktura?
      Säkerställ att belopp och leveransavgift stämmer innan fakturan skapas.`, 'Skapa kundfaktura', {
        confirmButtonText: 'Skapa kundfaktura',
        cancelButtonText: 'Avbryt',
        type: 'warning'
      }).then(() => {
        createCustomerInvoice(eventId).then(() => {
          ElMessage({
            type: 'success',
            message: 'Kundfakturan skapad!'
          });
        }).catch(() => {
          ElMessage({
            type: 'error',
            message: 'Kundfakturan kunde inte skapa!'
          });
        });
      });
    };

    const publishCustomerInvoiceModal = (publishCustomerInvoice, eventId) => {
      ElMessageBox.confirm(`Är du säker på att du vill publicera kundfakturan?
      Säkerställ att belopp och leveransavgift stämmer innan fakturan publiceras, kunden får ett mejl direkt.`, 'Publicera kundfakturan', {
        confirmButtonText: 'Publicera kundfakturan',
        cancelButtonText: 'Avbryt',
        type: 'warning'
      }).then(() => {
        publishCustomerInvoice(eventId).then(() => {
          ElMessage({
            type: 'success',
            message: 'Kundfakturan publicerad!'
          });
        }).catch(() => {
          ElMessage({
            type: 'error',
            message: 'Kundfakturan kunde inte publiceras!'
          });
        });
      });
    };

    const customerInvoicePaidModal = (customerInvoicePaid, eventId) => {
      ElMessageBox.confirm(`Är du säker på att du vill markera kundfakturan som betald?
      Säkerställ att beloppet inbetalt stämmer överäns med fakturan innan fakturan markeras som betald.`, 'Markera kundfakturan betald', {
        confirmButtonText: 'Markera kundfakturan betald',
        cancelButtonText: 'Avbryt',
        type: 'warning'
      }).then(() => {
        customerInvoicePaid(eventId).then(() => {
          ElMessage({
            type: 'success',
            message: 'Kundfakturan är nu markerad som betald!'
          });
        }).catch(() => {
          ElMessage({
            type: 'error',
            message: 'Kundfakturan kunde inte markeras som betald!'
          });
        });
      });
    }; // Partner Actions


    const createPartnerInvoiceModal = (createPartnerInvoice, eventId) => {
      ElMessageBox.confirm(`Är du säker på att du vill skapa en leverantörsfaktura?
      Säkerställ att belopp och leveransavgift stämmer innan leverantörsfaktura skapas.`, 'Skapa leverantörsfaktura', {
        confirmButtonText: 'Skapa leverantörsfaktura',
        cancelButtonText: 'Avbryt',
        type: 'warning'
      }).then(() => {
        createPartnerInvoice(eventId).then(() => {
          ElMessage({
            type: 'success',
            message: 'leverantörsfakturan skapad!'
          });
        }).catch(() => {
          ElMessage({
            type: 'error',
            message: 'leverantörsfakturan kunde inte skapa!'
          });
        });
      });
    };

    const publishPartnerInvoiceModal = (publishPartnerInvoice, eventId) => {
      ElMessageBox.confirm(`Är du säker på att du vill publicera leverantörsfakturan?
      Säkerställ att belopp och leveransavgift stämmer innan leverantörsfaktura publiceras, leverantör får ett mejl direkt.`, 'Publicera leverantörsfaktura', {
        confirmButtonText: 'Publicera leverantörsfakturan',
        cancelButtonText: 'Avbryt',
        type: 'warning'
      }).then(() => {
        publishPartnerInvoice(eventId).then(() => {
          ElMessage({
            type: 'success',
            message: 'leverantörsfakturan publicerad!'
          });
        }).catch(() => {
          ElMessage({
            type: 'error',
            message: 'leverantörsfakturan kunde inte publiceras!'
          });
        });
      });
    };

    const partnerInvoicePaidModal = (partnerInvoicePaid, eventId) => {
      ElMessageBox.confirm('Är du säker på att du vill markera leverantörsfakturan som betald?', 'Markera leverantörsfaktura betald', {
        confirmButtonText: 'Markera leverantörsfakturan betald',
        cancelButtonText: 'Avbryt',
        type: 'warning'
      }).then(() => {
        partnerInvoicePaid(eventId).then(() => {
          ElMessage({
            type: 'success',
            message: 'leverantörsfakturan är nu markerad som betald!'
          });
        }).catch(() => {
          ElMessage({
            type: 'error',
            message: 'leverantörsfakturan kunde inte markeras som betald!'
          });
        });
      });
    };

    return {
      archiveModal,
      createCustomerInvoiceModal,
      publishCustomerInvoiceModal,
      customerInvoicePaidModal,
      createPartnerInvoiceModal,
      publishPartnerInvoiceModal,
      partnerInvoicePaidModal
    };
  },

  computed: {
    eventCustomerInvoiceActive() {
      var _this$event, _this$event$event, _this$event$event$inv, _this$event$event$inv2, _this$event2, _this$event2$event, _this$event2$event$in, _this$event2$event$in2;

      return ((_this$event = this.event) === null || _this$event === void 0 ? void 0 : (_this$event$event = _this$event.event) === null || _this$event$event === void 0 ? void 0 : (_this$event$event$inv = _this$event$event.invoiceInformation) === null || _this$event$event$inv === void 0 ? void 0 : (_this$event$event$inv2 = _this$event$event$inv.customerInvoice) === null || _this$event$event$inv2 === void 0 ? void 0 : _this$event$event$inv2.invoiceDate) && !((_this$event2 = this.event) !== null && _this$event2 !== void 0 && (_this$event2$event = _this$event2.event) !== null && _this$event2$event !== void 0 && (_this$event2$event$in = _this$event2$event.invoiceInformation) !== null && _this$event2$event$in !== void 0 && (_this$event2$event$in2 = _this$event2$event$in.customerInvoice) !== null && _this$event2$event$in2 !== void 0 && _this$event2$event$in2.paid);
    },

    eventPartnerInvoiceActive() {
      var _this$event3, _this$event3$event, _this$event3$event$in, _this$event3$event$in2, _this$event4, _this$event4$event, _this$event4$event$in, _this$event4$event$in2;

      return ((_this$event3 = this.event) === null || _this$event3 === void 0 ? void 0 : (_this$event3$event = _this$event3.event) === null || _this$event3$event === void 0 ? void 0 : (_this$event3$event$in = _this$event3$event.invoiceInformation) === null || _this$event3$event$in === void 0 ? void 0 : (_this$event3$event$in2 = _this$event3$event$in.partnerInvoice) === null || _this$event3$event$in2 === void 0 ? void 0 : _this$event3$event$in2.invoiceDate) && !((_this$event4 = this.event) !== null && _this$event4 !== void 0 && (_this$event4$event = _this$event4.event) !== null && _this$event4$event !== void 0 && (_this$event4$event$in = _this$event4$event.invoiceInformation) !== null && _this$event4$event$in !== void 0 && (_this$event4$event$in2 = _this$event4$event$in.partnerInvoice) !== null && _this$event4$event$in2 !== void 0 && _this$event4$event$in2.paid);
    }

  },
  methods: { ...mapActions('events', ['archiveEvent', 'createCustomerInvoice', 'publishCustomerInvoice', 'customerInvoicePaid', 'createPartnerInvoice', 'publishPartnerInvoice', 'partnerInvoicePaid'])
  }
};
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-60964192"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0,
  class: "admin-actions"
};
const _hoisted_2 = {
  class: "admin-actions--customer"
};
const _hoisted_3 = {
  class: "admin-actions--partner"
};
const _hoisted_4 = {
  key: 1,
  class: "admin-actions admin-actions--archive"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$props$event, _$props$event$event, _$props$event$event$i, _$props$event$event$i2, _$props$event2, _$props$event2$event, _$props$event2$event$, _$props$event2$event$2, _$props$event3, _$props$event3$event, _$props$event3$event$, _$props$event3$event$2, _$props$event4, _$props$event4$event, _$props$event4$event$, _$props$event4$event$2;

  const _component_FormInput = _resolveComponent("FormInput");

  return _openBlock(), _createElementBlock(_Fragment, null, [!$props.event.archived && $props.event.status !== 'CREATED' && $props.event.status !== 'DECLINED' ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [!((_$props$event = $props.event) !== null && _$props$event !== void 0 && (_$props$event$event = _$props$event.event) !== null && _$props$event$event !== void 0 && (_$props$event$event$i = _$props$event$event.invoiceInformation) !== null && _$props$event$event$i !== void 0 && (_$props$event$event$i2 = _$props$event$event$i.customerInvoice) !== null && _$props$event$event$i2 !== void 0 && _$props$event$event$i2.invoiceDate) || $options.eventCustomerInvoiceActive ? (_openBlock(), _createBlock(_component_FormInput, {
    key: 0,
    group: "user",
    content: {
      name: 'create-customer-invoice',
      component: 'button',
      label: 'Skapa kundfaktura',
      type: 'primary'
    },
    onOnClick: _cache[0] || (_cache[0] = $event => $setup.createCustomerInvoiceModal(_ctx.createCustomerInvoice, $props.event.id))
  })) : _createCommentVNode("", true), !((_$props$event2 = $props.event) !== null && _$props$event2 !== void 0 && (_$props$event2$event = _$props$event2.event) !== null && _$props$event2$event !== void 0 && (_$props$event2$event$ = _$props$event2$event.invoiceInformation) !== null && _$props$event2$event$ !== void 0 && (_$props$event2$event$2 = _$props$event2$event$.customerInvoice) !== null && _$props$event2$event$2 !== void 0 && _$props$event2$event$2.published) && $options.eventCustomerInvoiceActive ? (_openBlock(), _createBlock(_component_FormInput, {
    key: 1,
    group: "user",
    content: {
      name: 'publish-customer-invoice',
      component: 'button',
      label: 'Publicera kundfakturan',
      type: 'primary'
    },
    onOnClick: _cache[1] || (_cache[1] = $event => $setup.publishCustomerInvoiceModal(_ctx.publishCustomerInvoice, $props.event.id))
  })) : _createCommentVNode("", true), $options.eventCustomerInvoiceActive ? (_openBlock(), _createBlock(_component_FormInput, {
    key: 2,
    group: "user",
    content: {
      name: 'customer-invoice-paid',
      component: 'button',
      label: 'Markera kundfakturan betald',
      type: 'primary'
    },
    onOnClick: _cache[2] || (_cache[2] = $event => $setup.customerInvoicePaidModal(_ctx.customerInvoicePaid, $props.event.id))
  })) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_3, [!((_$props$event3 = $props.event) !== null && _$props$event3 !== void 0 && (_$props$event3$event = _$props$event3.event) !== null && _$props$event3$event !== void 0 && (_$props$event3$event$ = _$props$event3$event.invoiceInformation) !== null && _$props$event3$event$ !== void 0 && (_$props$event3$event$2 = _$props$event3$event$.partnerInvoice) !== null && _$props$event3$event$2 !== void 0 && _$props$event3$event$2.invoiceDate) || $options.eventPartnerInvoiceActive ? (_openBlock(), _createBlock(_component_FormInput, {
    key: 0,
    group: "user",
    content: {
      name: 'create-customer-invoice',
      component: 'button',
      label: 'Skapa leverantörsfaktura',
      type: 'primary'
    },
    onOnClick: _cache[3] || (_cache[3] = $event => $setup.createPartnerInvoiceModal(_ctx.createPartnerInvoice, $props.event.id))
  })) : _createCommentVNode("", true), !((_$props$event4 = $props.event) !== null && _$props$event4 !== void 0 && (_$props$event4$event = _$props$event4.event) !== null && _$props$event4$event !== void 0 && (_$props$event4$event$ = _$props$event4$event.invoiceInformation) !== null && _$props$event4$event$ !== void 0 && (_$props$event4$event$2 = _$props$event4$event$.partnerInvoice) !== null && _$props$event4$event$2 !== void 0 && _$props$event4$event$2.published) && $options.eventPartnerInvoiceActive ? (_openBlock(), _createBlock(_component_FormInput, {
    key: 1,
    group: "user",
    content: {
      name: 'publish-customer-invoice',
      component: 'button',
      label: 'Publicera leverantörsfakturan',
      type: 'primary'
    },
    onOnClick: _cache[4] || (_cache[4] = $event => $setup.publishPartnerInvoiceModal(_ctx.publishPartnerInvoice, $props.event.id))
  })) : _createCommentVNode("", true), $options.eventPartnerInvoiceActive ? (_openBlock(), _createBlock(_component_FormInput, {
    key: 2,
    group: "user",
    content: {
      name: 'customer-invoice-paid',
      component: 'button',
      label: 'Markera leverantörsfakturan betald',
      type: 'primary'
    },
    onOnClick: _cache[5] || (_cache[5] = $event => $setup.partnerInvoicePaidModal(_ctx.partnerInvoicePaid, $props.event.id))
  })) : _createCommentVNode("", true)])])) : _createCommentVNode("", true), !$props.event.archived && $props.event.status !== 'CREATED' ? (_openBlock(), _createElementBlock("div", _hoisted_4, [!$props.event.archived ? (_openBlock(), _createBlock(_component_FormInput, {
    key: 0,
    group: "user",
    content: {
      name: 'archive-event',
      component: 'button',
      label: 'Arkivera bokningen',
      type: 'warning'
    },
    onOnClick: _cache[6] || (_cache[6] = $event => $setup.archiveModal(_ctx.archiveEvent, $props.event.id))
  })) : _createCommentVNode("", true)])) : _createCommentVNode("", true)], 64);
}
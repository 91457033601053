import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-4c543414"), n = n(), _popScopeId(), n);

const _hoisted_1 = ["href"];
const _hoisted_2 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Message = _resolveComponent("Message");

  return _openBlock(), _createBlock(_component_Message, {
    class: "invoice-info",
    type: "download"
  }, {
    default: _withCtx(() => [$options.isCustomer(_ctx.role) || $options.isAdmin(_ctx.role) ? (_openBlock(), _createElementBlock("a", {
      key: 0,
      href: `${$setup.SERVICE_CATERIFY}/events/invoice/${$props.event.id}`,
      target: "_blank"
    }, [_createElementVNode("span", null, _toDisplayString($options.customerStatus), 1)], 8, _hoisted_1)) : _createCommentVNode("", true), $options.isPartner(_ctx.role) || $options.isAdmin(_ctx.role) ? (_openBlock(), _createElementBlock("a", {
      key: 1,
      href: `${$setup.SERVICE_CATERIFY}/events/partner/invoice/${$props.event.id}`,
      target: "_blank"
    }, [_createElementVNode("span", null, _toDisplayString($options.partnerStatus), 1)], 8, _hoisted_2)) : _createCommentVNode("", true)]),
    _: 1
  });
}
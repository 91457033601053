import { mapActions, mapGetters } from 'vuex';
import { ElMessageBox, ElMessage } from 'element-plus';
import { isAdmin, isPartner, isCustomer } from '@/lib/helper';
import Messages from '@/components/MyPages/Messages';
import InvoiceInfo from '@/components/MyPages/InvoiceInfo';
import Message from '@/components/Message';
import Loading from '@/components/Loading';
import FormInput from '@/components/FormInput';
import OfferPrices from '@/components/Offers/OfferPrices';
import AdminActions from '@/components/MyPages/AdminActions';
export default {
  name: 'EventCard',
  components: {
    Messages,
    InvoiceInfo,
    Message,
    Loading,
    FormInput,
    OfferPrices,
    AdminActions
  },
  props: {
    event: {
      type: Object,
      required: true,
      default: () => {}
    },
    partner: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  emits: ['loadEvent'],

  setup() {
    const confirmModal = (confirmEvent, eventId) => {
      ElMessageBox.confirm(`Är ni säkra på att ni vill bekräfta bokningen?
      Det går inte att ändra bokningen efter att den har blivit bekräftad!`, 'Bekräfta bokningen', {
        confirmButtonText: 'Bekräfta',
        cancelButtonText: 'Avbryt',
        type: 'success'
      }).then(() => {
        confirmEvent(eventId).then(() => {
          ElMessage({
            type: 'success',
            message: 'Bokningen bekräftad!'
          });
        }).catch(() => {
          ElMessage({
            type: 'error',
            message: 'Bokningen kunde inte bekräftas!'
          });
        });
      }).catch(() => {});
    };

    const declineModal = (declineEvent, eventId) => {
      ElMessageBox.confirm(`Är ni säkra på att ni vill neka bokningen?
      Det går inte att se eller ändra bokningen efter att den har blivit nekad!`, 'Neka bokningen', {
        confirmButtonText: 'Neka',
        cancelButtonText: 'Avbryt',
        type: 'error'
      }).then(() => {
        declineEvent(eventId).then(() => {
          ElMessage({
            type: 'success',
            message: 'Bokningen nekad!'
          });
        }).catch(() => {
          ElMessage({
            type: 'error',
            message: 'Bokningen kunde inte nekas!'
          });
        });
      }).catch(() => {});
    };

    return {
      confirmModal,
      declineModal
    };
  },

  data() {
    return {
      expanded: false
    };
  },

  computed: { ...mapGetters('user', ['role']),

    status() {
      switch (this.event.status) {
        case 'CREATED':
          return 'Väntar på bekräftelse';

        case 'DECLINED':
          return 'Bokning nekad';

        case 'CONFIRMED':
          return 'Bokning bekräftad';

        case 'DELETED':
          return 'Bokning borttagen';

        default:
          return this.event.status;
      }
    },

    shouldShowInvoiceInfo() {
      return isCustomer(this.role) && this.event.event.invoiceInformation && this.event.event.invoiceInformation.customerInvoice && this.event.event.invoiceInformation.customerInvoice.published || isPartner(this.role) && this.event.event.invoiceInformation && this.event.event.invoiceInformation.partnerInvoice && this.event.event.invoiceInformation.partnerInvoice.published || isAdmin(this.role) && (this.event.event.invoiceInformation && this.event.event.invoiceInformation.customerInvoice || this.event.event.invoiceInformation && this.event.event.invoiceInformation.partnerInvoice);
    },

    isCompany() {
      return this.event.customerData.companyInfo && this.event.customerData.companyInfo.isCompany;
    },

    haveDeliveryAddress() {
      return this.event && this.event.event && this.event.event.eventInfo.streetName && this.event.event.eventInfo.postalCode && this.event.event.eventInfo.city;
    }

  },
  methods: { ...mapActions('events', ['sendMessage', 'loadEvent', 'confirmEvent', 'declineEvent']),
    isAdmin,
    isPartner,
    isCustomer,

    toggleEvent() {
      this.expanded = !this.expanded;
      this.$emit('loadEvent', this.event.id);
    },

    sendNewMessage(message) {
      this.sendMessage({
        id: this.event.id,
        message: message
      }).then(() => {
        this.loadEvent(this.event.id);
      }).catch(_ => {
        this.showError = true;
      });
    }

  }
};
import { mapGetters, mapActions } from 'vuex';
import partners from '@/content/Partners';
import FormInput from '@/components/FormInput';
import EventCard from '@/components/MyPages/EventCard';
import { isCustomer } from '@/lib/helper';
export default {
  name: 'MyPages',
  components: {
    FormInput,
    EventCard
  },

  data() {
    return {
      toggleDeliveryAddress: false,
      toggleInvoiceAddress: false,
      partners: partners
    };
  },

  computed: { ...mapGetters('events', ['events', 'archivedOffers', 'eventsSearch', 'error']),
    ...mapGetters('user', ['role', 'name', 'id', 'address', 'email', 'phone', 'isCompany', 'companyName', 'orgNumber', 'loggedIn']),

    deliveryAddress() {
      return this.address && this.address.filter(address => address.type === 'DELIVERY' || address.type === 'BOTH')[0];
    },

    invoiceAddress() {
      return this.address && this.address.filter(address => address.type === 'INVOICE' || address.type === 'BOTH')[0];
    },

    processedEvents() {
      if (this.events && this.events.length > 0) {
        let events = !isCustomer(this.role) ? this.archivedOffers.value && this.events || this.events && this.events.filter(event => !event.archived) : this.events;

        if (this.eventsSearch.value) {
          // Search after id, customerName, companyName, date and partner
          events = events.filter(event => event.id && event.id.toLowerCase().slice(event.id.length - 6).includes(this.eventsSearch.value.toLowerCase()) || event.customerName && event.customerName.toLowerCase().includes(this.eventsSearch.value.toLowerCase()) || event.customerData && event.customerData.contactInfo.name && event.customerData.contactInfo.name.toLowerCase().includes(this.eventsSearch.value.toLowerCase()) || event.customerData && event.customerData.companyInfo.companyName && event.customerData.companyInfo.companyName.toLowerCase().includes(this.eventsSearch.value.toLowerCase()) || event.date && event.date.includes(this.eventsSearch.value) || event.event && event.event.eventInfo.date && event.event.eventInfo.date.includes(this.eventsSearch.value) || event.time && event.time.includes(this.eventsSearch.value) || event.event && event.event.eventInfo.time && event.event.eventInfo.time.includes(this.eventsSearch.value) || event.partner && event.partner.toLowerCase().includes(this.eventsSearch.value.toLowerCase()) || event.event && event.event.selectedOffer.name.toLowerCase().includes(this.eventsSearch.value.toLowerCase()));
        }

        return events;
      }

      return [];
    }

  },

  mounted() {
    if (!this.events) {
      this.loadMyEvents();
    }
  },

  methods: { ...mapActions('events', ['loadMyEvents', 'loadEvent']),
    ...mapActions('user', ['logout']),
    isCustomer,

    localLoadEvent(id) {
      if (!this.events[this.events.findIndex(e => e.id === id)].loaded) {
        this.loadEvent(id);
      }
    },

    getPartner(partner) {
      const p = this.partners.filter(p => p.id === partner || p.id === partner.id)[0];

      if (p) {
        return p;
      } else if (partner.name) {
        return partner;
      } else {
        return {
          name: partner
        };
      }
    },

    reloadPage() {
      window.location.reload();
    }

  }
};
import { mapGetters, mapActions } from 'vuex';
import { isAdmin, isPartner, isCustomer } from '@/lib/helper';
import FormInput from '@/components/FormInput';
export default {
  name: 'Messages',
  components: {
    FormInput
  },
  props: {
    messages: {
      type: Array,
      required: true,
      default: () => []
    },
    id: {
      type: String,
      required: true,
      default: ''
    },
    partner: {
      type: String,
      required: true,
      default: ''
    },
    customer: {
      type: String,
      required: true,
      default: ''
    }
  },
  emits: ['sendMessage'],

  data() {
    return {
      message: '',
      emptyMessageError: false
    };
  },

  computed: { ...mapGetters('user', ['role'])
  },

  mounted() {
    if (this.messages.length > 0) {
      this.$nextTick(() => {
        var topPos = document.getElementById(`message-${this.messages[this.messages.length - 1].time}`).offsetTop;
        document.getElementById('messages-chat').scrollTop = topPos;
      });
    }

    if (this.role !== 'ADMIN') {
      this.addObserver();
    }
  },

  methods: { ...mapActions('events', ['readMessage']),
    isAdmin,
    isPartner,
    isCustomer,

    sendMessage() {
      if (this.message) {
        this.$emit('sendMessage', this.message);
        this.$refs.messageInput.updateValue('');
        this.emptyMessageError = false;
      } else {
        this.emptyMessageError = true;
      }
    },

    addObserver() {
      const unreadMessages = this.messages.filter(message => message.sentBy !== this.role && !message.read);
      const options = {
        root: document.getElementById('messages-chat'),
        rootMargin: '0px',
        threshold: 0.5
      };
      const observer = new IntersectionObserver((entry, observer) => {
        observer.unobserve(entry[0].target); // Set the read flag to true in the service

        this.readMessage({
          id: this.id,
          time: entry[0].target.id.slice(8)
        });
      }, options);
      unreadMessages.forEach(message => {
        observer.observe(document.getElementById(`message-${message.time}`));
      });
    }

  }
};
import { mapGetters } from 'vuex';
import { isAdmin, isPartner, isCustomer } from '@/lib/helper';
import Message from '@/components/Message';

const {
  SERVICE_CATERIFY
} = require('config');

export default {
  name: 'InvoiceInfo',
  components: {
    Message
  },
  props: {
    event: {
      type: Object,
      required: true,
      default: () => {}
    }
  },

  setup() {
    return {
      SERVICE_CATERIFY
    };
  },

  computed: { ...mapGetters('user', ['role']),

    customerStatus() {
      if (!this.event.event.invoiceInformation || !this.event.event.invoiceInformation.customerInvoice) {
        return;
      } else if (isCustomer(this.role)) {
        if (this.event.event.invoiceInformation.customerInvoice.paid) {
          return 'Fakturan är betald';
        } else if (new Date(this.event.event.invoiceInformation.customerInvoice.expireDate).getTime() < new Date().getTime()) {
          return 'Fakturan är förfallen, vänligen betala fakturan snarast för att undervika extra kostnader';
        } else if (this.event.event.invoiceInformation.customerInvoice.published) {
          return `Fakturan är obetald - sista betaldag: ${this.event.event.invoiceInformation.customerInvoice.expireDate}`;
        }
      } else if (isAdmin(this.role)) {
        if (this.event.event.invoiceInformation.customerInvoice.paid) {
          return 'Kund har betalat. ';
        } else if (this.event.event.invoiceInformation.customerInvoice.published && new Date(this.event.event.invoiceInformation.customerInvoice.expireDate).getTime() < new Date().getTime()) {
          return `Kundfakturan förfall ${this.event.event.invoiceInformation.customerInvoice.expireDate}. `;
        } else if (this.event.event.invoiceInformation.customerInvoice.published) {
          return `Kundfakturan är obetald - sista betaldag: ${this.event.event.invoiceInformation.customerInvoice.expireDate}. `;
        } else if (this.event.event.invoiceInformation.customerInvoice.invoiceDate) {
          return `Kundfaktura är skapad men inte skickad, Skapad: ${this.event.event.invoiceInformation.customerInvoice.invoiceDate}. `;
        }
      }

      return '';
    },

    partnerStatus() {
      if (!this.event.event.invoiceInformation || !this.event.event.invoiceInformation.partnerInvoice) {
        return;
      } else if (isPartner(this.role)) {
        if (this.event.event.invoiceInformation.partnerInvoice.paid) {
          return 'Fakturan är utbetald';
        } else if (this.event.event.invoiceInformation.partnerInvoice.published) {
          return `Fakturan betalas ut ${this.event.event.invoiceInformation.partnerInvoice.payDate}`;
        }
      } else if (isAdmin(this.role)) {
        if (this.event.event.invoiceInformation.partnerInvoice.paid) {
          return 'Utbetald till partner';
        } else if (this.event.event.invoiceInformation.partnerInvoice.published) {
          return `Utbetalas till partner: ${this.event.event.invoiceInformation.partnerInvoice.payDate}`;
        } else if (this.event.event.invoiceInformation.partnerInvoice.invoiceDate) {
          return `Leverantörsfaktura är skapad men inte skickad, Skapad: ${this.event.event.invoiceInformation.partnerInvoice.invoiceDate}. `;
        }
      }

      return '';
    }

  },
  methods: {
    isAdmin,
    isPartner,
    isCustomer
  }
};